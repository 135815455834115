import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import "./index@gen.scss";

const GeneratedCookiesPolicyIndex = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>Cookies Policy ｜PagerDuty正規販売代理店（株）</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta property="twitter:description" content="PagerDuty" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="PagerDuty" />

        <meta
          property="og:title"
          content="Cookies Policy ｜PagerDuty正規販売代理店（株）"
        />

        <meta property="og:description" content="PagerDuty" />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/cookies-policy//"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/cookies-policy//"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="cookiesPolicyWrapper">
        <div className="px-[25px] xl:px-0">
          <div className="cookiesPolicyWrapper__policy">
            <div className="cookiesPolicyWrapper__policy__container">
              <h2 className="cookiesPolicyWrapper__policy__title">
                Cookies Policy
              </h2>
              <div className="cookiesPolicyWrapper__policy__rule">
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  This Cookies Policy describes the different types of cookies
                  that (“Digital Stacks Corporation (DSC)”, “we”, “us” or “our”)
                  uses on its website (https://en.corp.digitalstacks.net/) and
                  the choices that users have.
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  Digital Stacks Corporation (DSC) (“we”, “us” or “our”) wants
                  to ensure that your visit to our website is smooth, trusty and
                  as useful to you as possible. For this reason, we want to use
                  cookies to make our website relevant to your interests and
                  needs.
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  初めて当社の Web サイトにアクセスすると、この Cookie
                  ポリシーに従って Cookie
                  の使用に同意するよう求められます。同意すると、引き続きデバイスに保存されます。
                </p>
              </div>
              <div className="cookiesPolicyWrapper__policy__rule">
                <h2 className="cookiesPolicyWrapper__policy__rule__title">
                  クッキーとは？
                </h2>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  当社のウェブサイトにアクセスすると、情報が「Cookie」の形でデバイスに保存されます。
                  Cookie
                  は、デバイスに保存される小さなファイルで、特定の設定とデータを保存して、ブラウザー経由で当社の
                  Web サイトと交換します。
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  原則として、明示的な許可がない限り、Cookie
                  を介して個人データを収集することはありません。
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  デバイスを認識させたくない場合は、デバイスからすべての Cookie
                  を削除するか、すべての Cookie をブロックするか、Cookie
                  が保存される前に警告を受け取るようにブラウザを設定してください。これを行う方法の簡単な説明を以下に示します。
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  Cookie がないと、当社の Web
                  サイトの特定の機能が機能しなくなったり、正しく機能しなくなったりする可能性があることに注意してください。
                </p>
              </div>
              <div className="cookiesPolicyWrapper__policy__rule">
                <h2 className="cookiesPolicyWrapper__policy__rule__title">
                  どのような種類の COOKIE を使用していますか?
                </h2>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  Strictly necessary cookies
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  これらの Cookie
                  は、ウェブサイトが機能するために必要であり、システムでオフにすることはできません。これらは通常、プライバシー設定の設定、ログイン、フォームへの入力など、サービスの要求に相当するユーザーのアクションに応じてのみ設定されます。これらの
                  Cookie
                  が保存される前に、ブラウザをブロックまたは警告を受け取るように設定できますが、その場合、当社の
                  Web サイトの一部が正しく機能しない場合があります。
                </p>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  Functional cookies
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  これらの Cookie により、当社の Web
                  サイトの機能が強化され、カスタマイズされたビデオや画像が表示されます。それらは、当社または当社のウェブサイトにサービスを追加したサードパーティプロバイダーによって設定される場合があります。これらの
                  Cookie
                  がないと、これらのサービスの一部またはすべてが正しく機能しない場合があります。
                </p>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  Performance/Analytics Cookies
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  これらの Cookie を使用すると、訪問数とトラフィック
                  ソースをカウントできるため、ウェブサイトのパフォーマンスを測定および改善できます。それらは、最も人気のあるページと最も人気のないページを理解し、訪問者が当社の
                  Web
                  サイト内をどのように移動するかを理解するのに役立ちます。これらの
                  Cookie がないと、お客様がいつ当社の Web
                  サイトにアクセスしたかがわからず、そのパフォーマンスを監視することもできません。
                </p>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  Targeting Cookies
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  これらの Cookie は、当社の広告パートナーによって当社の Web
                  サイトを通じて設定される場合があります。これらの企業は、お客様の興味のプロファイルを作成し、お客様に関連する広告を表示するために使用する場合があります。それらは、ブラウザとデバイスを一意に識別することに基づいています。これらの
                  Cookie
                  がないと、ターゲットを絞った広告が表示されなくなります。
                </p>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  How to control cookies
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  ほとんどのブラウザは、デフォルトで Cookie
                  を受け入れるように設定されています。必要に応じて、Cookie
                  を無効にするか、Cookie
                  が保存されているときに通知するようにブラウザを設定できます。
                  Cookie をブロックするか、Cookie を配置するための当社の Web
                  サイトの要求を受け入れることを拒否した場合、当社の Web
                  サイトの一部が正しく機能しないか、まったく機能しない可能性があります。
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  次のリンクにアクセスすると、Web ブラウザのヘルプ ページで
                  Cookie の管理に関する詳細情報を見つけることができます。
                </p>
                <ul className="cookiesPolicyWrapper__policy__rule__link">
                  <li>
                    <a
                      href="https://support.google.com/chrome/answer/95647"
                      target="_blank"
                    >
                      グーグルクローム
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                      target="_blank"
                    >
                      モジラ ファイアフォックス
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies"
                      target="_blank"
                    >
                      マイクロソフトエッジ
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/11.0/mac/10.13"
                      target="_blank"
                    >
                      サファリ (macOS)
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.apple.com/en-us/HT201265"
                      target="_blank"
                    >
                      サファリ (iOS)
                    </a>
                  </li>
                </ul>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  その他のブラウザーについては、ブラウザーの製造元が提供するドキュメントを参照してください。
                </p>
                <h3 className="cookiesPolicyWrapper__policy__rule__subtitle">
                  連絡先
                </h3>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  If you have any queries in relation to this notice or our use
                  of cookies, please contact us at: 〒141-0001
                  東京都品川区北品川5-5-15
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  ‍大崎ブライトコア4階SHIP
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  Osaki Bright Core 4F SHIP
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__text">
                  5-5-15, Kita-Shinagawa, Shinagawa-ku, Tokyo 141-0001 Japan
                </p>
                <p className="cookiesPolicyWrapper__policy__rule__contact">
                  Email:
                  <a href="mailto:​​info@digitalstacks.net">
                    ​​ info@digitalstacks.net
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedCookiesPolicyIndex;
